import React from 'react'
import './Charingsolutions.css';

export default function Chargingsolutions() {
  return (
    <div className='chargingSolutions'>
      {/* <div className='backroundimageDefa'>
        <img src="https://storage.googleapis.com/eletech/defa-power-latausasema-ja-rfid-avaintagi.png" alt='defa' />
      </div> */}
      <p>
      <div className='kotilatausratkaisut'>
        <img src="https://storage.googleapis.com/eletech/Power_charging_lady_height_2948_1000x1000%20(2).jpeg" alt='defa' />
        <p>
        <h2>Kotilatausratkaisut</h2>
        <br />
        Suosittelemme hankkimaan kiinteän latausaseman kotiisi, jos mahdollista. Kiinteä latausasema tarjoaa useita etuja, kuten optimaalisen lataustehon ja akkujen elinkaaren pidentämisen, mikä tekee siitä autovalmistajien ja asiantuntijoiden suositteleman vaihtoehdon.
        Valitessasi oikean latausaseman kotiisi on tärkeää ottaa huomioon asumismuotosi ja sähköliitäntäsi ominaisuudet. Rivi- tai kerrostaloasunnoissa latausaseman vanhan lämpötolpan johtoon asentaminen voi olla ainoa vaihtoehto, mikä rajaa lataustehon maksimissaan 3.7 kW (1-vaiheinen asennus).
        Omakotitaloasujilla on yleensä enemmän joustavuutta lataustehon suhteen, ja he voivat hyödyntää kolmivaihevirtaa. Asennamme omakotitaloasujille mielellämme kiinteän latausaseman tai 3-vaihde voimavirtapistorasian latauslaitetta varten.
        </p>
      </div>
      <br />
        <div className='balancerContainer'>
          <img src="https://storage.googleapis.com/eletech/defa_balancer.jpg" alt='defa balancer' />
          <p>
            <h3>Kuormanhallinta</h3>
            <br />
            Dynaamisen kuormanhallinnan toteuttamiseksi sähkökeskukseen asennetaan erillinen kuormanhallintalaite, joka seuraa asuntosi sähkönkulutusta. Tämä laite kommunikoi latausaseman kanssa ja säätää lataustehon dynaamisesti sen mukaan, kuinka paljon sähköä on käytettävissä kiinteistössä.
            Suosittelemme dynaamista kuormanhallintaa, mikäli haluat saada maksimaalisen lataustehon käyttöösi ja sinun ei tarvitse miettiä pääsulakkeiden palamista muiden kiinteistön sähkölaitteiden käytön aikana.
            <br />
            <br />
            Manuaalinen kuormanhallinta tarjoaa edullisemman vaihtoehdon dynaamiselle kuormanhallinnalle.
            Tässä vaihtoehdossa latausteho parametroidaan latausasemalta karkeasti noin puoleen sähkön korkean kulutusajan aikana.
            Manuaalinen kuormanhallinta kuuluu asennushintaan ja toteutamme sen aina asennuksissamme, mikäli dynaamista
            kuormanhallintaa ei ole tullut valituksi.
          </p>
        </div>
        <br />
        <div className='superSukoContainer'>
          <img src="https://storage.googleapis.com/eletech/supersuko.jfif" alt='defa balancer' />
          <p>
            <h3>Supersuko pistorasia</h3>
            <br />
            Meiltä voit tilata myös edullisemman latausratkaisun supersuko pistorasian asennettuna kotiisi. Tässä latausratkaisussa latausteho on tarjoamistamme ratkaisuista pienin,
            joten latausaika ajoneuvollesi on pitkä.
            Asennamme supersuko pistorasioita esimerkiksi auton lämmityskoteloiden tilalle.
            <br />
            <br />
          </p>
        </div>
        <br />
        <div className='taloyhtiot'>
        <img src="https://storage.googleapis.com/eletech/kerrostalo.jpg" alt='defa balancer' />
        <p>
          <h3>Taloyhtiöille</h3>
          <br />
          Toteutamme latausratkaisuja sekä kartoituksia myös taloyhtiöille. Taloyhtiöiden latausratkaisut ovat yleensä hieman monimutkaisempia kuin omakotitalojen, sillä asukkaiden latausinfra mitoitetaan kiinteistön sähköliittymän mukaan.
          <br />
          <br />
          Suunnittelemme ja toteutamme taloyhtiöiden latausratkaisut aina kiinteistön nykyiset resurssit ja tulevaisuuden tarpeet huomioiden. Asennamme latausjärjestelmät joustavasti niin, että ne ovat helposti laajennettavissa uusille käyttäjille, mikä varmistaa järjestelmän skaalautuvuuden. Varmistamme, että asukkaiden lataustarpeet täytetään tehokkaasti ilman turhia lisäkustannuksia.
          <br />
          <br />
          Tarjoamme myös älykkäitä latauksen hallintajärjestelmiä, joiden avulla latausvirran käyttö ja kustannukset voidaan jakaa oikeudenmukaisesti asukkaiden kesken. Kartoitamme huolellisesti kiinteistön latausvalmiudet ja autamme taloyhtiötä valitsemaan juuri oikean ratkaisun, joka täyttää sekä nykyiset että tulevat lataustarpeet.
        </p>
        </div>
      </p>
    </div>
  )
}
