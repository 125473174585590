import React from 'react'
import './Zaptec.css';
import { useState } from 'react';

export default function Zaptec() {
  const images = [
    'https://storage.googleapis.com/eletech/Zaptec%20Pro%20MID.jpg',
    'https://storage.googleapis.com/eletech/Zaptec_september-44.jpg',
    'https://storage.googleapis.com/eletech/Zaptec_pro_mid_front.png',
    'https://storage.googleapis.com/eletech/hallinta_zaptec.png',
  ];

  const [mainImage, setMainImage] = useState(images[2]);

  const changeMainImage = (newImage) => {
    setMainImage(newImage);
  };

  return (
    <div className='Zaptec'>
      <div className='zaptec-container'>
        <img src={mainImage} alt='zaptec' />
        <div className='zaptecimgSlide'>
        {images
            .filter((image) => image !== mainImage) // Filter out the main image
            .map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`zaptec-${index}`}
                onClick={() => changeMainImage(image)}
              />
            ))}
        </div>
      </div>
      <div className='zaptecInfoText'>
        <p><strong>Zaptec Pro on ihanteellinen latausratkaisu taloyhtiöille, tarjoten huipputason älykkyyttä ja tehokkuutta erityisesti sen ainutlaatuisen langattoman kuormanhallinnan ansiosta.</strong></p>
        <p>
          <h3>Edistyksellinen kuormanhallinta </h3>
          Zaptec Pro on markkinoiden ainoa latausasema, joka optimoi perinteisen kuormanhallinnan lisäksi myös kolmivaihevirran käyttöä maksimaalisesti. Latausasema osaa automaattisesti siirtyä kolmivaihelatauksesta yksivaihelataukseen ja vaihdella vaiheiden välillä optimoidakseen sähkönkäytön, mikä tekee siitä erinomaisen jakamaan taloyhtiöiden sähkökapasiteetin.
        </p>
        <p>
          <h3>Maksimaalinen kapasiteetin hyödyntäminen</h3>
          Zaptec Pro pystyy älykkään optimoinnin ansiosta saavuttamaan yli 95 prosentin käyttöasteen kuormittuneissa järjestelmissä. Tämä tarkoittaa, että latausasema osaa ohjata virtaa tehokkaammin, ja taloyhtiön asukkaat saavat enemmän irti käytettävissä olevasta sähköstä, myös silloin kun kuormitus on suurta.
        </p>
        <p>
          <h3>
            Tehokas kolmi- ja yksivaihelataus
          </h3>
          Zaptec Pro voi lennosta vaihtaa kolmivaihelatauksen yksivaihelataukseksi, mikä mahdollistaa suuremman tehon hyödyntämisen yksittäisellä vaiheella. Esimerkiksi tilanteessa, jossa kolmivaihejärjestelmän kapasiteetti on rajallinen, laite voi siirtyä yksivaihelataukseen ja tarjota suuremman tehon yksittäiseen autoon, optimoiden näin kaikkien latausasemien käytön.
        </p>
        <p>
          <h3>
            Langaton hallinta ja seuranta
          </h3>
          Zaptec Pro voidaan yhdistää älykkääseen latausohjelmistoon, mikä mahdollistaa langattomat ohjelmistopäivitykset ja lataustapahtumien seurannan reaaliajassa. Tämä tekee taloyhtiön latausjärjestelmän hallinnasta helppoa ja tehokasta ilman tarvetta manuaalisille toimenpiteille.
        </p>
        <p>
          <h3>Optimaalinen ratkaisu taloyhtiöille</h3>
          Zaptec Pro:n edistynyt kuormanhallinta varmistaa, että taloyhtiö voi hyödyntää olemassa olevaa sähköverkkoa mahdollisimman tehokkaasti, ilman tarvetta kalliisiin sähkökapasiteetin lisäyksiin. Tämä tekee siitä kustannustehokkaan ja pitkäaikaisen ratkaisun monen sähköauton talouksiin.
          Latausasema on saatavilla myös MID-sertifioidulla energiamittarilla.
        </p>
        <p>
          Zaptec Pro on suunniteltu erityisesti taloyhtiöiden vaativiin tarpeisiin, tarjoten älykkään ja joustavan latausratkaisun, joka optimoi sähkönkäytön ja maksimoi kapasiteetin hyödyntämisen. Jos taloyhtiösi etsii modernia, tehokasta ja skaalautuvaa latausratkaisua, Zaptec Pro on varma valinta.
        </p>
        <br />
        <br />
        <a href="https://storage.googleapis.com/eletech/Zaptec%20Pro.pdf" target="_blank" rel="noopener noreferrer">Manuaali</a>
      </div>
    </div>
  )
}
