import React from 'react'
import './Tesla.css';

import { useState } from 'react';

export default function Tesla() {

  const images = [
    'https://storage.googleapis.com/eletech/tesla-wall-connector-latausasema-4.jpg',
    'https://storage.googleapis.com/eletech/tesla-wall-connector-latausasema-3.jpg',
    'https://storage.googleapis.com/eletech/tesla-wall-connector-latausasema-1.jpg',
    'https://storage.googleapis.com/eletech/tesla-wall-connector-latausasema-2.jpg',
    'https://storage.googleapis.com/eletech/tesla_2.jpeg',
    'https://storage.googleapis.com/eletech/tesla_1.jpeg'
  ];
  const [mainImage, setMainImage] = useState(images[2]);

  const changeMainImage = (newImage) => {
    setMainImage(newImage);
  };

  return (
    <div className='Tesla'>
      <div className='tesla-container'>
        <img src={mainImage} alt='tesla' />
        <div className='teslaimgSlide'>
        {images
            .filter((image) => image !== mainImage) // Filter out the main image
            .map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`tesla-${index}`}
                onClick={() => changeMainImage(image)}
              />
            ))}
        </div>
      </div>
      <div className='teslaInfoText'>
        <p>
          <h3>Optimoitu Tesla-käyttäjille</h3>
          <br />
          Wall Connector on suunniteltu erityisesti Tesloille, mikä takaa parhaan mahdollisen latauskokemuksen. Teslan omistajille Wall Connector tarjoaa ominaisuuksia, joita et saa muista latausasemista, kuten mahdollisuuden avata auton latausportti suoraan latauskaapelissa olevasta painikkeesta, mikä tekee latausprosessista vaivattomamman.
          <br />
          <br />
          <h3>
          Täydellinen yhteensopivuus Teslan kanssa</h3>
          <br />
          Tesla sovelluksen avulla voit hallita ja seurata lataustasi reaaliajassa, ajastaa latauksia ja nähdä lataushistorian suoraan puhelimesta. Tämä saumaton integraatio Tesla-auton ja latausaseman välillä tekee lataamisesta mahdollisimman helppoa ja tehokasta.
          <br />
          <br />
          <h3>Teslan oma teknologia ja laatu</h3>
          <br />
          Koska Wall Connector on Teslan itse suunnittelema ja valmistama, voit luottaa siihen, että se on yhteensopiva kaikkien Tesla-mallien kanssa ja hyödyntää täysimääräisesti Teslan teknologiaa.
          <br />
          <br />
          <h3>Langattomat päivitykset</h3>
          <br />
          Wall Connector pysyy ajan tasalla uusimmilla ominaisuuksilla netin kautta, aivan kuten Tesla-ajoneuvotkin saavat säännöllisesti ohjelmistopäivityksiä. Tämä varmistaa, että latausasemasi kehittyy jatkuvasti ja toimii aina parhaalla mahdollisella tavalla.
          <br />
          <br />
          <a href="https://storage.googleapis.com/eletech/Gen_3_Tesla_Wall_Connector_manuaali_suomeksi.pdf" target="_blank" rel="noopener noreferrer">Manuaali</a>
        </p>
      </div>
    </div>
  )
}
