import React from 'react'
import { useState } from 'react';
import './Light.css';
import { GrFormPrevious } from "react-icons/gr";
import { GrFormNext } from "react-icons/gr";
export default function Light() {
    const [lightImage, setLightImage] = useState(0);
    const nextImage2 = () => {
        setLightImage((prevIndex) => (prevIndex + 1) % 4);
    };

    const prevImage2 = () => {
        setLightImage((prevIndex) => (prevIndex - 1 + 4) % 4);
    };

    const lightimages = [
        'https://storage.googleapis.com/eletech/hexagon_1.jpeg',
        'https://storage.googleapis.com/eletech/hexagon_2.jpeg',
        'https://storage.googleapis.com/eletech/dr_dirt_hexagon_14grit_in_use_1.jpeg',
        'https://storage.googleapis.com/eletech/dr_dirt_hexagon_5grit_in_use_1.jpg'

    ];
    return (
        <div className='light'>
            <p className="lightP2">
                <table>
                    <tbody>
                        <tr>
                            <td><strong>Olemme iloisia voidessamme ilmoittaa, että olemme juuri aloittaneet jälleenmyynnin Dr. Dirt Hexagon -valoille.</strong></td>
                        </tr>
                        <br />
                        <tr>
                            <td>Myymme kuusikulmaisia Hexagon-valoja avaimet käteen -palveluna aina valaistuksen suunnittelusta toteutukseen.</td>
                        </tr>
                        <br />
                        <tr>
                            <td><strong>Dr. Dirt Hexagon Garage Sky Gen2</strong>
                            </td>
                        </tr>
                        <br />
                        <tr>
                            <td>Suuren suosion saavuttaneiden Hexagon-valojen uudistettu valoelementti on valmistettu korkealaatuisista ja pitkäikäisistä komponenteista. Hexagon-valoelementtien avulla toteutetaan nykyaikaisia, kirkkaita ja miellyttäviä valaistuksia esimerkiksi asuinrakennuksiin, messuille, näyttelyihin, kuntosaleille, liiketiloihin, harrastustiloihin tai autotalleihin.</td>
                        </tr>
                        <br />
                        <tr>
                            <td><strong>Modulaarinen valaistus</strong></td>
                        </tr>
                        <br />
                        <tr>
                            <td>Valot ovat modulaarisia, voimme suunnitella ja rakentaa haluamaasi tilaan sopivan kokoisen valaistuksen käyttötarkoitus huomioon ottaen. Hexagon-valoja voi valita yhdestä valoelementistä aina täyden katon kattavaan valaistukseen.</td>
                        </tr>
                        <br />
                        <tr>
                            <td><strong>Hintaesimerkit</strong></td>
                        </tr>
                        <br />
                        <tr>
                            <td>Hintaesimerkkeihimme sisältyvät valaistuksen suunnittelu kanssasi sekä asennus Dr. Dirt Hexagon -valoelementeillä.
                            </td>
                        </tr>
                        <tr>
                            <td> Hinnat vaihtelevat valaistuksen koon mukaan:</td>
                        </tr>
                        <tr>
                            <td>
                                1 Grid system (105 x 115cm alue): Alk. 204 € sis. alv
                            </td>
                        </tr>
                        <tr>
                            <td>
                                3 Grid system (95 x 246cm alue): Alk. 349 € sis. alv
                            </td>
                        </tr>
                        <tr>
                            <td>5 Grid system (240 x 165cm alue): Alk. 494 € sis. alv</td>
                        </tr>
                        <tr>
                            <td>8 Grid system (290 x 305cm alue): Alk. 620 € sis. alv</td>
                        </tr>
                        <tr>
                            <td>14 Grid system (240 x 410cm alue): Alk. 975 € sis. alv</td>
                        </tr>
                        <br />
                        <tr>
                            <td>Lisäpalveluista laskutamme erikseen.</td>
                        </tr>
                        <br />
                        <tr>
                            <td><strong>Uudiskohteet</strong></td>
                        </tr>
                        <br />
                        <tr>
                            <td>Uudiskohteissa hintaan vaikuttavat matka keskukselta kohteeseen (syöttökaapelin pituus), mahdolliset sähkökeskukselle tehtävät työt sekä valaistuksen ohjaus. Uudiskohteisiin hinta arvioidaan työmäärän mukaan.</td>
                        </tr>
                        <br />
                        <tr>
                            <td><strong>Pelkät tuotteet ilman asennuspalvelua</strong></td>
                        </tr>
                        <br />
                        <tr>
                            <td>Myymme Dr. Dirt Hexagon -valaisimia myös ilman asennuspalvelua. Pyydä tarjous!</td>
                        </tr>
                        <tr>
                            <td>Muistutamme kuitenkin, että valaistuksen kytkentä tapahtuu pienjännitteellä (230V), joten asennuksen saa tehdä vain sähköasentaja.</td>
                        </tr>
                        <div className="lcontainer">
                            <div className='lightContainer'>
                                <strong className="lightxt">Dr. Dirt Hexagon Garage Sky Gen2</strong>
                                <img src={lightimages[lightImage]} alt={`Kuva ${lightImage + 1}`} onClick={nextImage2} />
                                <p className='lightlähteet'>kuvien lähteet: autodude.fi</p>
                                <div className="lightbuttonContainer">
                                    <GrFormPrevious className='buttonSize' onClick={prevImage2}>
                                    </GrFormPrevious>
                                    <GrFormNext className='buttonSize' onClick={nextImage2}>
                                    </GrFormNext>
                                </div>
                            </div>
                        </div>
                        <tr>
                            <td>
                                <strong>Tekniset tiedot:</strong>
                            </td>

                        </tr>
                        <br />
                        <tr>
                            <td>Värilämpötila: 6500K</td>
                        </tr>
                        <tr>
                            <td>Käyttöjännite: 220-240 V AC
                            </td>
                        </tr>
                        <tr>
                            <td>Ilmoitettu käyttöikä: 50 000 h
                            </td>
                        </tr>
                        <tr>
                            <td>Värintoistoindeksi (CRI): + 80
                            </td>
                        </tr>
                        <tr>
                            <td>Lampun valovirta (lm): 110-120 lm/W
                            </td>
                        </tr>
                        <tr>
                            <td>Teho: 8 W / 0.5 m
                            </td>
                        </tr>
                        <tr>
                            <td>Valaisukulma: 180°
                            </td>
                        </tr>
                        <tr>
                            <td>IP-luokitus: IP20
                            </td>
                        </tr>
                        <tr>
                            <td>Hyväksynnät: CE
                            </td>
                        </tr>
                        <tr>
                            <td>Käyttölämpötila: -20°C - +45°C
                            </td>
                        </tr>
                        <tr>
                            <td>Takuu: 12 kk
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Maahantuoja: Handshake.fi
                            </td>
                        </tr>

                    </tbody>
                </table>
            </p>
        </div>
    )
}
