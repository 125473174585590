import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import './About.css';

const { REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_ID, REACT_APP_API_KEY } = process.env;

export default function About() {
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Tarkista, että sähköpostissa on @-merkki
    if (!email.includes('@')) {
      alert('Sähköpostiosoitteen tulee sisältää @-merkki.');
      return;
    }

    // Tarkista, että kaikki kentät ovat täytettyjä
    if (!name || !email || !message) {
      alert('Kaikki kentät ovat pakollisia.');
      return;
    }

    const templateParams = {
      from_name: 'Lähettäjän Nimi: ' + name,
      from_email: 'Lähettäjän Sähköposti: ' + email,
      message: 'Viesti: ' + message
    };

    emailjs.send(REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_ID, templateParams, REACT_APP_API_KEY)
      .then((result) => {
        console.log(result.text);
        // Tyhjennä lomake
        setMessage('');
        setEmail('');
        setName('');
        alert('Viesti lähetetty onnistuneesti! Olemme yhteydessä sinuun pian.');
      }, (error) => {
        console.log(error.text);
      });
  };

  useEffect(() => {
  }, []);

  return (
    <div>
      <p className='about'>
        Jekotek Oy on kahden kokeneen ammattilaisen muodostama sähköalan yritys ja meillä onkin molemmilla yli 8 vuoden vankka kokemus alalta.
        Olemme sitoutuneet tarjoamaan asiakkaillemme luotettavaa ja ammattitaitoista palvelua kaikissa sähköalan tarpeissa.<br></br><br></br>
        Asiantuntemuksemme ulottuu laajasti sähköalan eri osa-alueille, olipa kyse sitten kodin sähköasennuksista tai teollisuuden sähköjärjestelmistä.
        Panostamme laatuun ja turvallisuuteen jokaisessa työssämme, jotta asiakkaamme voivat luottaa siihen, että asennuksemme hoidetaan ammattitaidolla ja huolella.<br></br><br></br>
        Vapaa-ajallamme löydämme iloa voimailulajeista. Uskomme vahvasti terveelliseen elämäntapaan ja fyysiseen kuntoon, mikä heijastuu myös työhömme energisenä ja motivoituneena työntekona.<br></br><br></br>
        Lisäksi olemme innostuneita uusista teknologioista, erityisesti sähköautonlatausinfrastruktuurista. Seuraamme aktiivisesti alalla tapahtuvaa kehitystä ja haluamme olla ajan hermoilla tarjotessamme asiakkaillemme viimeisintä tietoa ja parhaita ratkaisuja sähköautoilun tarpeisiin.
        <p className='aboutOtaYhteyttä'>Ota yhteyttä</p>
        <form onSubmit={handleSubmit} className="formWrapper">
          <div>
            <label>Nimi:</label>
            <input
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="formInput"
            />
          </div>

          <div>
            <label>Sähköposti:</label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="formInput"
            />
          </div>

          <div>
            <label>Viesti:</label>
            <textarea
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="formTextarea"
            />
          </div>

          <button type="submit" className="formButton">
            Lähetä
          </button>
        </form>
      </p>
    </div>
  );
}
