import React from 'react'
import './References.css'
import { FaStar } from "react-icons/fa";

export default function References() {
  return (
    <div className='refet'>
    <div class="scroll-container">
            <img src="https://storage.googleapis.com/eletech/twc1.jpeg" alt='Tesla Wall Connector'/>
            <img src="https://storage.googleapis.com/eletech/twc2.jpeg" alt='Tesla Wall Connector' />
            <img src="https://storage.googleapis.com/eletech/twc4.jpeg" alt='Tesla Wall Connector'/>
            <img src="https://storage.googleapis.com/eletech/idc1.jpeg" alt='Elli Id Charger'/>
            <img src="https://storage.googleapis.com/eletech/kaivuu.jpeg" alt='Maankaivuu'/>
            <img src="https://storage.googleapis.com/eletech/imgK.jpeg" alt='Välikatto'/>
            <img src="https://storage.googleapis.com/eletech/sunPane.jpeg" alt='Aurinkopaneeli'/>
            <img src="https://storage.googleapis.com/eletech/keskus.jpeg" alt='Sähkökeskus'/>
    </div>
    <br/>
    <br/>
    <p className='yellow'>
    <FaStar></FaStar>
    <FaStar></FaStar>
    <FaStar></FaStar>
    <FaStar></FaStar>
    <FaStar></FaStar>
    </p>
    <p className='fontBold'>
    Tesla wall connector asennus |    
    "Osaava, aktiivinen ja mukava, kiitos!"
    </p>
    <br/>
    <p className='yellow'>
    <FaStar></FaStar>
    <FaStar></FaStar>
    <FaStar></FaStar>
    <FaStar></FaStar>
    <FaStar></FaStar>
    </p>
    <p className='fontBold1'>
    Sähköauton latausaseman asennus lämpökotelon tilalle |     
    "Urakkatarjous tuli nopeasti, ja yhteydenotto sujui vaivattomasti puhelimitse. Ajankohta löytyi myös nopealla aikataululla, ja itse asennus sujui sovitun aikataulun mukaan. Suosittelen muillekin!"
    </p>
    <br/>
    <p className='yellow'>
    <FaStar></FaStar>
    <FaStar></FaStar>
    <FaStar></FaStar>
    <FaStar></FaStar>
    <FaStar></FaStar>
    </p>
    <p className='fontBold1'>
    Wallbox pulsar asennus |     
    "Erittäin asiallinen sähkömies ja asennus priimaluokkaa. Suosittelen!"
    </p>
    <br/>
    <p className='scroll-container-teksti'>
    Katso lisää referenssejä sosiaalisesta mediastamme <a href="https://www.facebook.com/profile.php?id=61557573072446">Facebook</a> & <a href="https://www.instagram.com/jekotek_oy/?hl=en">Instagram</a>
    </p>
    </div>
  )
}
