import './App.css';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Light from './components/Light';
import React from 'react';
import Allservices from './components/Allservices';
import References from './components/References';
import Defa from './components/Defa';
import Chargingsolutions from './components/Chargingsolutions';
import Tesla from './components/Tesla';
import GoeCharger from './components/GoeCharger';
import Zaptec from './components/Zaptec';
import ChargeAmps from './components/ChargeAmps';

import { Route, Routes, Link } from 'react-router-dom';
import { useState } from 'react';

function App() {
  const [showNav, setShowNav] = useState(false);

  const handleNavItemClick = () => {
    setShowNav(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/allservices" element={<Allservices />} />
        <Route path="/light" element={<Light />} />
        <Route path="/references" element={<References />} />
        <Route path="/defa" element={<Defa />} />
        <Route path="/chargingsolutions" element={<Chargingsolutions />} />
        <Route path="/tesla" element={<Tesla />} />
        <Route path="/goecharger" element={<GoeCharger />} />
        <Route path="/zaptec" element={<Zaptec />} />
        <Route path="/chargeamps" element={<ChargeAmps />} />
      </Routes>


      <header>
        <Link to="/" className="logo" onClick={handleNavItemClick}>
        </Link>
        <div className={`menu-toggle ${showNav ? 'open' : ''}`} onClick={() => setShowNav(!showNav)}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <nav className={`navigation ${showNav ? 'show' : ''}`}>
          <Link to="/" onClick={handleNavItemClick}>
            Etusivu
          </Link>
          <Link to="/allservices" onClick={handleNavItemClick}>
            Palvelut
          </Link>
          <Link to="/references" onClick={handleNavItemClick}>
            Referenssit
          </Link>
          <Link to="/about" onClick={handleNavItemClick}>
            Meistä
          </Link>
          <Link to="/contact" onClick={handleNavItemClick}>
            Yhteystiedot
          </Link>

      

        </nav>
      </header>

      <footer className='footer'>
        <table>
          <tr>
            <td className='highlight'>
              Yhteystiedot</td>
            <td className='highlight2'>Media</td>
          </tr>
          <tr>
            <td className='media2'>Jekotek Oy</td>
            <td className='media'><a href="https://www.facebook.com/profile.php?id=61557573072446">Facebook</a></td>
          </tr>
          <tr>
            <td className='media2'>Y-Tunnus: 3424950-9</td>
            <td className='media'><a href="https://www.instagram.com/jekotek_oy/?hl=en">Instagram</a></td>
          </tr>
          <tr>
            <td className='media2'>info@jekotek.fi</td>
          </tr>
        </table>
      </footer>

    </div>

  );
}

export default App;