import React from 'react';
import './Contact.css';
import { MdOutlineEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { MdAccountCircle } from "react-icons/md";

export default function Contact() {
  return (
    <div className='contactWrapper'>
              <h2>Työtilaukset & asennukset</h2>
      <div className='contactContainer'>
        <img src="https://storage.googleapis.com/eletech/firman_logo_1.jpg" alt='jekotek' />
        <p><MdAccountCircle /> Konsta Juola</p>
        <p><FaPhoneAlt /> +358 50 412 4351</p>
        <p><MdOutlineEmail/> info@jekotek.fi</p>
        <a href="mailto:info@jekotek.fi">Ota yhteyttä sähköpostilla</a>
      </div>

      <div className='contactContainer' style={{ marginBottom: '140px' }}>
        <img src="https://storage.googleapis.com/eletech/firman_logo_1.jpg" alt='jekotek' />
        <p><MdAccountCircle /> Jere Nikupeteri</p>
        <p><FaPhoneAlt />  +358 40 595 9064</p>
        <p><MdOutlineEmail/> info@jekotek.fi</p>
        <a href="mailto:info@jekotek.fi">Ota yhteyttä sähköpostilla</a>
      </div>
    </div>
  );
}