import React from 'react'
import './Defa.css'
import { useState } from 'react';
export default function Defa() {
  const images = [
    'https://storage.googleapis.com/eletech/Power_charging_lady_height_2948_1000x1000%20(2).jpeg',
    'https://storage.googleapis.com/eletech/DEFA%20Power%20sein%C3%A4ll%C3%A4.jpg',
    'https://storage.googleapis.com/eletech/defa-power-latausasema-ja-rfid-avaintagi.png',
    'https://storage.googleapis.com/eletech/defa_asiakas.jpeg',
  ];

  const [mainImage, setMainImage] = useState(images[2]);

  const changeMainImage = (newImage) => {
    setMainImage(newImage);
  };


  return (
    <div className='Defa'>
      <div className='defa-container'>
        <img src={mainImage} alt='defa' />
        <div className='imgSlide'>
          {images
              .filter((image) => image !== mainImage) // Filter out the main image
              .map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`defa-${index}`}
                  onClick={() => changeMainImage(image)}
                />
              ))}
        </div>
      </div>
      <div className='defaInfoText'>
        <p>
          <h3>DEFA Power on erinomainen valinta sähköauton latausasemaksi useista syistä</h3>
          <br />
          Norjalainen laatutuote
          DEFA Power on suunniteltu pohjoismaisiin, ankariin olosuhteisiin. Se kestää pakkasta, kosteutta ja vaihtelevia sääolosuhteita, mikä tekee siitä täydellisen valinnan Suomen ilmastoon.
          <br />
          <br />
          <h3>Älykäs lataus</h3>
          <br />
          DEFA Power hyödyntää älykkäitä ominaisuuksia, kuten mahdollisuuden ladata sähköä silloin, kun hinta on alimmillaan, mikä auttaa säästämään kustannuksissa.
          <br />
          <br />
          <h3>Tehokas ja joustava</h3>
          <br />
          Maksimiteholla lataus on 22 kW, mutta se voidaan asentaa joustavasti myös matalemmalle teholle (esim. 11 kW tai 3,7 kW) vastaamaan kotisi sekä autosi latauskapasiteettia.
          <br />
          <br />
          <h3>Helppokäyttöinen ja selkeä</h3>
          <br />
          Latausaseman näytöltä ja sovelluksesta näet kaikki tarvittavat tiedot, kuten ladatun energian ja latausajan, ja kiinteä 6 metrin kaapeli tekee latauksesta vaivatonta. Kaapelin saa kiedottua siististi latausaseman ympärille, joten kaapeli pysyy poissa maasta.
          DEFA Power tarjoaa mahdollisuuden ladata PDF-muotoisia kuukausiraportteja latauksista, mikä on erityisen hyödyllistä työsuhdeautoa käyttävälle.
          <br />
          <br />
          <h3>Luotettava turvallisuus</h3>
          <br />
          DEFA on pitkän linjan autotekniikan asiantuntija, joten voit luottaa latausaseman turvallisuuteen ja luotettavuuteen, myös vaativissa olosuhteissa.
          <br />
          <br />
          <a href="https://storage.googleapis.com/eletech/defa_tekst.pdf" target="_blank" rel="noopener noreferrer" color="black">Manuaali</a>
        </p>
      </div>
    </div>
  )
}
