import React from 'react';
import { useState } from 'react';
import './ChargeAmps.css';

export default function ChargeAmps() {
    const images = [
        'https://storage.googleapis.com/eletech/chrage_amps_1.jpg',
        'https://storage.googleapis.com/eletech/charge_amps_5.jfif',
        'https://storage.googleapis.com/eletech/charge_amps_3.webp',
        'https://storage.googleapis.com/eletech/charge_amps_2.avif',
        'https://storage.googleapis.com/eletech/ChargeAmp_4.webp'

    ];
    const [mainImage, setMainImage] = useState(images[0]);

    const changeMainImage = (newImage) => {
        setMainImage(newImage);
    };


    return (
        <div className='ChargeAmps'>
            <div className='ChargeAmps-container'>
                <img src={mainImage} alt='Charge Amps' />
                <div className='chargeAmps-imgSlide'>
                    {images
                        .filter((image) => image !== mainImage) // Filter out the main image
                        .map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt={`chargeAmps-${index}`}
                                onClick={() => changeMainImage(image)}
                            />
                        ))}
                </div>
            </div>
            <div className='ChargeAmps-infoText'>
                <p>
                    <strong>
                    Charge Amps Halo 11 kW -latausasema on erinomainen valinta erityisesti niille, jotka arvostavat kestävyyttä, tyylikästä muotoilua ja pohjoisiin olosuhteisiin suunniteltua luotettavuutta.
                        </strong>
                </p>
                <p>
                <h3>Kompakti mutta tehokas</h3>
                    Vaikka Halo on kooltaan pieni, se on todellinen tehopakkaus. Se tarjoaa luotettavan 11 kW lataustehon, mikä tekee siitä täydellisen valinnan päivittäiseen käyttöön.
                </p>
                <p>
                <h3>Huippuluokan säänkestävyys</h3>
                Halossa on markkinoiden korkein IP-luokitus (IP66), joka takaa, että se kestää kaikkein vaativimmatkin sääolosuhteet. Latausjohto pysyy taipuisana ja joustavana jopa -25 °C:ssa, mikä varmistaa luotettavan toiminnan ankarissa pohjoisissa olosuhteissa.
                </p>
                <p>
                <h3>Älykäs ja käytännöllinen muotoilu</h3>
                    LED-valaistus ohjaa käyttäjää pimeässä, ja kiinteä latausjohto on aina käyttövalmiina ilman sotkuja. Lisäksi Halo sisältää sukopistorasian, joka on täydellinen esimerkiksi sisätilanlämmittimen liittämiseen.

                </p>
                <p>
                <h3>Langattomat päivitykset ja yhteydet</h3>
                    Charge Amps Halo voidaan yhdistää verkkoon ja ohjata älykkäällä puhelinsovelluksella. Ohjelmistopäivitykset tulevat automaattisesti langattomasti, mikä takaa sen, että laitteesi pysyy ajan tasalla ja tarjoaa uusimmat toiminnot ilman ylimääräistä vaivaa.
                    Charge Amps Halo yhdistää kestävyydeltään ja toiminnallisuudeltaan huippuluokan latausteknologian tyylikkääseen ja käytännölliseen muotoiluun. Se on täydellinen valinta niille, jotka tarvitsevat luotettavan ja älykkään latausratkaisun, joka toimii moitteettomasti kaikissa sääolosuhteissa.
                </p>
                <p>
                <a href="https://storage.googleapis.com/eletech/Charge%20amps%20esite.pdf" target="_blank" rel="noopener noreferrer">Manuaali</a>
                </p>
                        
            </div>
        </div>
    )
}
