import React from 'react'
import './GoeCharger.css';
import { useState } from 'react';

export default function GoeCharger() {
  const images = [
    'https://storage.googleapis.com/eletech/go-e-charger-gemini-flex-22kw-view4.png',
    'https://storage.googleapis.com/eletech/go-e-charger-gemini-flex-22kw-view43.png',
    'https://storage.googleapis.com/eletech/gemini-flex-22kw-smartphone-combo-en%20(1).png',
    'https://storage.googleapis.com/eletech/goe_customer.jpeg'
  ];

  const [mainImage, setMainImage] = useState(images[2]);

  const changeMainImage = (newImage) => {
    setMainImage(newImage);
  };
  return (
    <div className='Goe'>
      <div className='goe-container'>
        <img src={mainImage} alt='defa' />
        <div className='goeimgSlide'>
          {images
            .filter((image) => image !== mainImage) // Filter out the main image
            .map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`goe-${index}`}
                onClick={() => changeMainImage(image)}
              />
            ))}
        </div>
      </div>
      <div className='goeInfoText'>
        <p>
          <strong>Go-e Gemini Flex 11 kW -latausasema on erinomainen valinta sähköauton omistajille, jotka arvostavat joustavuutta ja liikuteltavuutta. Tämä latauslaite tarjoaa tehokasta latausta ja samalla mahdollisuuden ottaa laite helposti mukaan esimerkiksi mökille.</strong>
          <br />
          <br />
          <h3>Kannettava ja joustava latausratkaisu</h3>
          <br />
          Go-e Gemini Flex on suunniteltu liikuteltavaksi, mikä tarkoittaa, että voit irrottaa latausaseman helposti voimavirtapistorasiasta ja ottaa sen mukaasi. Tämä on täydellinen ratkaisu esimerkiksi niille, jotka haluavat käyttää samaa latauslaitetta kotona, mökillä tai muissa kohteissa.
          <br />
          <br />
          <h3>11 kW latausteho </h3>
          <br />

          Latausasema tarjoaa jopa 11 kW tehon, mikä tekee siitä tehokkaan ratkaisun sekä kotiin että työpaikalle. Voit kytkeä laitteen voimavirtapistorasiaan ja hyödyntää nopeampaa latausta verrattuna perinteisiin kotitalouspistorasioihin.
          <br />
          <br />
          <h3>Mukana voimavirtapistorasia</h3>
          <br />

          Asennukseen sisältyy myös voimavirtapistorasia, mikä tekee käyttöönotosta vaivatonta ja varmistaa, että latausasema toimii optimaalisesti heti asennuksen jälkeen. Sinun ei tarvitse huolehtia ylimääräisistä tarvikkeista tai asennusvaiheista.
          <br />
          <br />
          <h3>Kätevät ominaisuudet</h3>
          <br />

          Go-e Gemini Flex sisältää älykkäitä ominaisuuksia, kuten langaton yhteys ja latauksen hallinta mobiilisovelluksen kautta. Voit helposti seurata latausta, ajastaa sen ja tarkastella lataushistoriaa, mikä tekee laitteen käytöstä sujuvaa ja mukautuvaa arjen tarpeisiin.
          Latausasemasta löytyy myös pörssisähkölataus.
          <br />
          <br />
          <h3>Käytännöllinen koko ja kevyt muotoilu</h3>
          <br />

          Go-e Gemini Flex on kompakti ja kevyt, joten se on helppo kuljettaa ja asentaa uuteen paikkaan. Voit helposti pakata sen mukaan autoon ja käyttää sitä joustavasti missä tahansa, missä on saatavilla voimavirtapistorasia.

          Go-e Gemini Flex 11 kW on täydellinen ratkaisu, jos etsit kannettavaa, tehokasta ja monipuolista latausasemaa, jonka voit ottaa mukaan esimerkiksi mökille tai muualle matkoillesi.
          <br />
          <br />
          <a href="https://cdn.shopify.com/s/files/1/0465/4412/6112/files/go_e_gemini_flex_tuotekortti.pdf?v=1694090145" target="_blank" rel="noopener noreferrer">Manuaali</a>
        </p>
      </div>
    </div>
  )
}
